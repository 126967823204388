import { RxDBClient } from "$lib/rxDBclient";

async function initRxDBClient() {
	try {
	  await RxDBClient.getInstance();
	} catch (error) {
	  console.error(error);
	}
  }
  
  initRxDBClient();

export function handleError({ error, event }: { error: Error; event: any }) {
	console.error('An error occurred:', error);
	return {
	  message: 'An error occurred. Please try again later.',
	};
  }
  